import clsx from 'clsx'
import Button from 'components/forms/Button'
import NavBackIcon from 'components/icons/NavBackIcon'
import { isAbsent } from 'lib/utils'

type Props = {
  onClick: () => void
  active?: boolean
  classes?: string
}

let PrevButton = ({ onClick, active = true, classes }: Props) => {
  return (
    <Button
      onClick={onClick}
      aria-label='Назад'
      className={clsx(
        'flex items-center justify-center rounded-full border bg-white active:bg-grey',
        {
          'border-greyA1': active,
          'border-grey': !active,
          'h-10 w-10': isAbsent(classes),
        },
        classes
      )}
      disabled={!active}
    >
      <NavBackIcon
        className={clsx('h-4 w-4', {
          'text-greyA1': active,
          'text-grey': !active,
        })}
      />
    </Button>
  )
}

export default PrevButton
