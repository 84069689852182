import clsx from 'clsx'

let MinusIcon = ({ className }: { className?: string }) => (
  <svg
    className={clsx(className)}
    viewBox='0 0 20 2'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M19.6607 0.0859375V1.50951H0.688477V0.0859375H19.6607Z'
      fill='currentColor'
    />
  </svg>
)

export default MinusIcon
