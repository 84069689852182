import clsx from 'clsx'
import Button from 'components/forms/Button'
import MinusIcon from 'components/icons/MinusIcon'
import PlusIcon from 'components/icons/PlusIcon'
import { MEDIA_URL } from 'config/constants'
import useCartItem from 'hooks/useCartItem'
import useSlug from 'hooks/useSlug'
import formatMoney from 'lib/formatMoney'
import { inProduction } from 'lib/misc'
import {
  isAbsent,
  isNonEmptyArray,
  isNonEmptyObj,
  isNonEmptyString,
  isPresent,
  isSame,
  toValues,
} from 'lib/utils'

import useCheckSiteStatus from 'hooks/useCheckSiteStatus'
import React, { useCallback, useMemo } from 'react'
import {
  IAddToCartResult,
  IProduct,
  ITooltipInfo,
  useAddCartItemMutation,
} from 'store/api'
import Img from '../../common/Img'
import IntLink from '../../common/IntLink'

interface Props extends IProduct {
  categorySlug?: string
  className?: string
  descClassName?: string
  tooltips?: ITooltipInfo[]
  cb: (...args: any) => any
  quantity: number
  items: IAddToCartResult[]
}

const emptyItem = { key: '0', product: { id: 0 } } as IAddToCartResult

let ProductCard = ({
  id,
  short_desc,
  weight,
  name,
  image,
  price,
  type,
  className,
  options,
  cb,
  meta,
  slug,
  categorySlug,
  variation_id,
  variations_count,
  typeMeta,
  quantity,
  items,
}: Props) => {
  const itemsDepsTrigger = items.map((x) => x.product.id).join('__')
  const item = useMemo(
    () =>
      quantity > 0
        ? items.find((i) => i.product.id === id)!
        : emptyItem,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [itemsDepsTrigger, id, quantity]
  )

  const {
    handleUpdateCartItem,
    handleRemoveCartItem,
    isUpdateCartItemLoading,
    isRemoveCartItemLoading,
  } = useCartItem(item, items)

  const { queryLng } = useSlug()

  const [addCartItem, { isLoading: addCartItemLoading }] =
    useAddCartItemMutation()

  const _addCartItem = useCallback(async () => {
    await addCartItem({
      id,
      quantity: 1,
      lng: queryLng,
      price: price!,
      variation_id,
      type,
      ingredients: [],
    })
    if (inProduction) {
      window.gtag!('event', 'add_to_cart', {
        items: [
          {
            id: id,
            name: name,
            price: price!,
            quantity: 1,
          },
        ],
      })
      window.ym!(16650304, 'reachGoal', 'addToCartYa')
      window.dataLayer.push({ ecommerce: null })
      window.dataLayer.push({
        event: 'addToCart',
        ecommerce: {
          currencyCode: 'RUB',
          add: {
            products: [
              {
                id: id,
                name: name,
                price: price!,
                quantity: 1,
              },
            ],
          },
        },
      })
    }
  }, [addCartItem, id, price, queryLng, name, variation_id, type])

  const isSimpleProduct =
    (type === 'simple' || variations_count == 1) &&
    isAbsent(options?.ingsgroups)

  const onOrderClick = useCallback(async () => {
    if (isSimpleProduct) {
      await _addCartItem()
    } else cb()
  }, [_addCartItem, cb, isSimpleProduct])

  const decreaseItemCount = useCallback(() => {
    if (quantity == 1) handleRemoveCartItem()
    else handleUpdateCartItem(-1)
  }, [handleRemoveCartItem, handleUpdateCartItem, quantity])

  const onUpdateCartItem = useCallback(
    (increase: boolean) => {
      if (isSimpleProduct) {
        if (increase) handleUpdateCartItem(1)
        else {
          decreaseItemCount()
        }
      } else cb()
    },
    [cb, handleUpdateCartItem, decreaseItemCount, isSimpleProduct]
  )

  const maxQtyAchieved = useMemo(() => {
    return isPresent(options)
      ? quantity == options.prod_max_quantity
      : false
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options?.prod_max_quantity, quantity])

  const variationInfo = useMemo(() => {
    if (isNonEmptyObj(typeMeta?.attributes)) {
      const attrs = toValues(typeMeta?.attributes)
      const info = isNonEmptyArray(attrs)
        ? attrs[0]['name']
        : undefined
      return info
    }
    return undefined
  }, [typeMeta])

  const { isAcceptingOrders } = useCheckSiteStatus()

  const weightIsPresent = isNonEmptyString(weight) && weight !== '0'

  return (
    <div
      className={clsx(
        'border group lg:hover:border-primary border-lightGrey p-3.5',
        className
      )}
    >
      <div
        className='relative cursor-pointer aspect-w-1 aspect-h-1 w-full rounded-lg overflow-hidden'
        onClick={cb}
      >
        <Img
          src={`${MEDIA_URL}${image}`}
          alt={meta.img_alt}
          title={meta.img_title}
          layout='fill'
          objectFit='cover'
        />
        <div className='group-hover:flex hidden items-end bg-dark bg-opacity-70 absolute top-0 left-0 w-full h-full p-2 text-white text-xs font-semibold leading-snug'>
          {short_desc?.length > 80
            ? short_desc.substring(0, 80) + '...'
            : short_desc}
        </div>
      </div>

      <div className='py-4 whitespace-nowrap flex justify-between items-center min-h-[50px]'>
        <div className='font-bold text-lg leading-none tracking-tight '>
          {price > 0 ? formatMoney(price) : ''}
        </div>
        <div className='ml-1 text-xs text-right w-1/2 md:w-2/3 overflow-hidden overflow-ellipsis md:text-sm leading-snug text-bodyGrey font-light'>
          {isPresent(variationInfo)
            ? `${variationInfo} ${weightIsPresent ? '/' : ''} `
            : ''}
          {weightIsPresent ? `${weight} г` : ''}
        </div>
      </div>

      <div
        className={clsx(
          'cursor-pointer font-semibold text-sm2 md:text-base2 leading-none md:leading-tight tracking-tighter line-clamp-2 h-6 md:h-14 md:line-clamp-none'
        )}
        onClick={isAbsent(categorySlug) ? () => cb() : undefined}
      >
        {isPresent(categorySlug) ? (
          <IntLink href={`/product/${categorySlug}/${slug}`}>
            {name}
          </IntLink>
        ) : (
          name
        )}
      </div>

      {isAcceptingOrders && (
        <div
          className={clsx(
            'flex justify-start items-center mt-2 lg:mt-6 gap-4',
            {
              hidden: quantity === 0,
            }
          )}
        >
          <Button
            aria-label='Уменьшить количество'
            onClick={() => onUpdateCartItem(false)}
            disabled={
              isUpdateCartItemLoading || isRemoveCartItemLoading
            }
            className='rounded-lg bg-lightYellow w-10 h-10 p-3.5 hover:bg-primary'
          >
            <MinusIcon className='w-full h-full mx-auto my-auto' />
          </Button>

          <div className='text-sm leading-6 tracking-tight text-dark'>
            {quantity}
          </div>

          <Button
            aria-label='Увеличить количество'
            onClick={() => onUpdateCartItem(true)}
            disabled={maxQtyAchieved || isUpdateCartItemLoading}
            className='rounded-lg bg-lightYellow w-10 h-10 p-3.5 hover:bg-primary'
          >
            <PlusIcon className='w-full h-full mx-auto my-auto' />
          </Button>
        </div>
      )}

      {isAcceptingOrders && (
        <Button
          className={clsx(
            'mt-2 lg:mt-6 rounded-lg w-5/6 sm:w-max h-10 sm:px-8 btn-primary py-2 text-sm leading-none font-semibold tracking-tight relative',
            {
              hidden: quantity > 0,
            }
          )}
          onClick={onOrderClick}
          disabled={addCartItemLoading}
          loading={addCartItemLoading}
          loadingClasses='h-3 w-3 mx-1 bg-accent'
        >
          В корзину
        </Button>
      )}
    </div>
  )
}

export default React.memo(
  ProductCard,
  (prevProps, nextProps) =>
    isSame(prevProps.id, nextProps.id) &&
    isSame(prevProps.quantity, nextProps.quantity)
)
