// import { inProduction } from 'lib/misc'
import { inProduction } from 'lib/misc'
import { isDiff, isPresent } from 'lib/utils'
import { useCallback } from 'react'
import {
  IAddToCartResult,
  IGetCartResult,
  useRemoveCartItemMutation,
  useUpdateCartItemMutation
} from 'store/api'
import useSlug from './useSlug'

let useCartItem = (
  item: IGetCartResult['items'][number],
  items: IAddToCartResult[]
) => {
  let {
    action,
    key,
    product: { id, variation_id, type },
    name,
    quantity,
    ingredients,
  } = item

  let { queryLng } = useSlug()

  let [
    updateCartItem,
    {
      isError: isUpdateCartItemError,
      isLoading: isUpdateCartItemLoading,
    },
  ] = useUpdateCartItemMutation()
  let [removeCartItem, { isLoading: isRemoveCartItemLoading }] =
    useRemoveCartItemMutation()

  let handleRemoveCartItem = useCallback(async () => {
    if (isPresent(action)) {
      let anotherGift = items.find(
        (x) => isPresent(x.action) && isDiff(x.key, key)
      )

      isPresent(anotherGift) &&
        (await removeCartItem({
          key: anotherGift.key,
          lng: queryLng,
        }))
    }

    void removeCartItem({ key, lng: queryLng })
    if (inProduction) {
      window.gtag!('event', 'remove_from_cart', {
        items: [
          {
            id: id,
            name: name,
            quantity: quantity,
          },
        ],
      })
      window.dataLayer.push({ ecommerce: null })
      window.dataLayer.push({
        event: 'removeFromCart',
        ecommerce: {
          currencyCode: 'RUB',
          remove: {
            products: [
              {
                id: id,
                name: name,
                quantity: quantity,
              },
            ],
          },
        },
      })
    }
  }, [
    action,
    items,
    key,
    queryLng,
    removeCartItem,
    id,
    quantity,
    name,
  ])

  let handleUpdateCartItem = useCallback(
    (x: number) => {
      void updateCartItem({
        id,
        quantity: quantity + x,
        key,
        variation_id: +variation_id,
        lng: queryLng,
        type,
        ingredients,
      })
    },
    [
      id,
      key,
      quantity,
      queryLng,
      type,
      updateCartItem,
      variation_id,
      ingredients,
    ]
  )

  return {
    handleUpdateCartItem,
    handleRemoveCartItem,
    isUpdateCartItemError,
    isUpdateCartItemLoading,
    isRemoveCartItemLoading,
  }
}

export default useCartItem
