import clsx from 'clsx'

let PlusIcon = ({ className }: { className?: string }) => (
  <svg
    className={clsx(className)}
    viewBox='0 0 16 17'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8.64513 7.70926H15.4751V9.30925H8.64513V16.5092H7.12736V9.30925H0.297363V7.70926H7.12736V0.509277H8.64513V7.70926Z'
      fill='currentColor'
    />
  </svg>
)

export default PlusIcon
