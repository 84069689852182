import clsx from 'clsx'

let NavBackIcon = ({ className }: { className?: string }) => (
  <svg
    className={clsx(className)}
    viewBox='0 0 23 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M21.7222 9.99997H2.27777M2.27777 9.99997L9.91666 2.36108M2.27777 9.99997L9.91666 17.6389'
      stroke='currentColor'
      strokeWidth='2'
      strokeLinecap='square'
    />
  </svg>
)

export default NavBackIcon
